export const Firebase_Config = {
  apiKey: 'AIzaSyCxsxdatEx3irhQaikXqf9_M94nXrk7irA',
  authDomain: 'statsapps-5b71c.firebaseapp.com',
  databaseURL: 'https://statsapps-5b71c.firebaseio.com',
  projectId: 'statsapps-5b71c',
  storageBucket: '',
  messagingSenderId: '78960443585',
  appId: '1:78960443585:web:2d8a472e435d558f',
};

/*
export const Firebase_Config = {
  apiKey: 'AIzaSyBVbuZLTjZPHRy5XKFfF-yDsWfP95ZGM60',
  authDomain: 'novartisric.firebaseapp.com',
  databaseURL:
    'https://novartisric-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'novartisric',
  storageBucket: 'novartisric.appspot.com',
  messagingSenderId: '185043839079',
  appId: '1:185043839079:web:dd4022a9e67daea8030cc2',
  measurementId: 'G-VFH63BVB5F',
};*/
