import React, { Component } from 'react';
import './Moderation.css';
import { Firebase_Config } from '../Config/config';
import * as firebase from 'firebase/app';
import 'firebase/database';
import Alert from 'react-bootstrap/Alert';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

class Moderation extends Component {
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
    this.db = firebase.database().ref().child('questionsNovartisPIR1');
    this.dbSelect = firebase
      .database()
      .ref()
      .child('questionSellectionnerNovartisPIR1');

    this.state = {
      questions: [],
      questionsSelect: [],
      stateModal: false,
      question: '',
      questionSelect: '',
    };
  }
  componentWillMount() {
    this.db.on('child_removed', (snapshot) => {
      var id = snapshot.key;
      const prequestions = this.state.questions;
      for (let i = 0; i < prequestions.length; i++) {
        if (prequestions[i].id === id) {
          prequestions.splice(i, 1);
          i--;

          break;
        }
      }
      this.setState({ questions: prequestions });
    });
  }
  componentWillUnmount() {}
  componentDidMount() {
    const prequestions = this.state.questions;
    this.db.on('child_added', (snap) => {
      prequestions.push({
        id: snap.key,
        question: snap.val().question,
      });
      this.setState({ questions: prequestions });
    });

    this.dbSelect.on('child_added', (snap) => {
      const prequestionSelect = this.state.questionsSelect;

      prequestionSelect.push({
        id: snap.key,
        question: snap.val().question,
        date: snap.val().date,
        nom: snap.val().nom,
      });
      this.setState({ questionsSelect: prequestionSelect });
      console.log("child_added'");
      console.log(prequestionSelect);
    });

    this.dbSelect.on('child_removed', (snapshot) => {
      var id = snapshot.key;
      const prequestionSelect = this.state.questionsSelect;
      const longeur = prequestionSelect.length;

      for (let i = 0; i < prequestionSelect.length; i++) {
        if (prequestionSelect[i].id === id) {
          prequestionSelect.splice(i, 1);
          i--;

          break;
        }
      }
      this.setState({ questionsSelect: prequestionSelect });

      //window.location.reload(false);
    });
  }

  agrandir = (event) => {
    this.setState({ question: event.target.value, stateModal: true });
  };
  fermer = (event) => {
    this.setState({ stateModal: false });
  };

  delete(event) {
    let questionId = event.target.value;
    let deleteRef = firebase
      .database()
      .ref()
      .child(`/questionsNovartisPIR1/${questionId}`);
    let r = window.confirm(
      'Êtes-vous sûr de vouloir supprimer définitivement cette question ?'
    );
    if (r === true) {
      deleteRef
        .remove()
        .then(() => {
          console.log('delete success');
        })
        .catch((error) => {
          console.log('Failed :' + error);
        });
    }
  }

  // Selectionner les questions
  selectionner(value1, value2) {
    let questionName = value1;
    let questionId = value2;

    if (questionName !== '') {
      let uid = firebase
        .database()
        .ref()
        .child('questionSellectionnerNovartisPIR1')
        .push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        question: questionName,
        date: n,
      };

      let updates = {};

      updates['/questionSellectionnerNovartisPIR1/' + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(console.log('La question a bien été selectionner'));
    }

    let deleteRef = firebase
      .database()
      .ref()
      .child(`/questionsNovartisPIR1/${questionId}`);

    deleteRef
      .remove()
      .then(() => {
        console.log('delete success');
      })
      .catch((error) => {
        console.log('Failed :' + error);
      });
  }

  //  De selectionner question
  deSelectionner(value1, value2) {
    let questionName = value1;
    let questionId = value2;

    if (questionName !== '') {
      let uid = firebase.database().ref().child('questionsNovartisPIR1').push()
        .key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        question: questionName,
        date: n,
      };

      let updates = {};

      updates['/questionsNovartisPIR1/' + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(console.log('La question a bien été selectionner'));
    }

    let deleteRef = firebase
      .database()
      .ref()
      .child(`/questionSellectionnerNovartisPIR1/${questionId}`);

    deleteRef
      .remove()
      .then(() => {
        console.log('delete success');
      })
      .catch((error) => {
        console.log('Failed :' + error);
      });
  }

  render() {
    var Mymodal = '';
    var template = '';

    if (this.state.stateModal === true) {
      Mymodal = (
        <div className="oModal">
          <div>
            <header>
              <h2>La question posée :</h2>
            </header>
            <section>
              <p>{this.state.question} </p>
            </section>
            <footer className="cf">
              <Button
                className="btn droite"
                color="secondary"
                onClick={this.fermer}
              >
                Fermer
              </Button>
            </footer>
          </div>
        </div>
      );
    }

    return (
      <div className="containerModeration">
        <div className="ListeModeration">
          <div className="logoDIV">
            <div className="textGrisContainer"></div>

            <img
              src="https://h8.events.novartis.fr/demo/IMG/novartis-logo.svg"
              alt="logo novartis"
              width="100"
            />
          </div>
          <hr />

          <h5> Les questions selectionnées </h5>
          <hr />
          {this.state.questionsSelect.map((home) => {
            return (
              <Alert key={home.id} className="alert" variant="dark">
                <h4 className="textVerte"> {home.question}</h4>{' '}
                <Button
                  className="btnAgrandir"
                  value={home.question}
                  onClick={this.agrandir}
                >
                  Agrandir{' '}
                </Button>
                {'  '}
                {
                  <Button
                    className="btnSupprimer"
                    color="primary"
                    value={home.question}
                    onClick={(e) => this.deSelectionner(home.question, home.id)}
                  >
                    Désélectionner
                  </Button>
                }
                {'  '}
              </Alert>
            );
          })}
          <hr />
          {/**
           *
           *
           */}
          <h5> Les questions possées </h5>
          <hr />
          {this.state.questions.map((home) => {
            return (
              <Alert key={home.id} className="alert" variant="dark">
                <div className="ChatHeader">
                  <p>{home.nom}</p>
                  <p> {home.date}</p>
                </div>
                <h6 className="textNoir"> {home.question}</h6>
                <Button
                  className="btnAgrandir"
                  value={home.question}
                  onClick={this.agrandir}
                >
                  Agrandir{' '}
                </Button>
                {'  '}
                <Button
                  className="btnSupprimer"
                  color="secondary"
                  value={home.id}
                  onClick={this.delete}
                >
                  Supprimer
                </Button>
                <Button
                  className="btnSupprimer"
                  color="primary"
                  value={home.question}
                  onClick={(e) => this.selectionner(home.question, home.id)}
                >
                  Sélectionner
                </Button>
              </Alert>
            );
          })}
        </div>
        {Mymodal}
      </div>
    );
  }
}

export default Moderation;
