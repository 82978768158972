import './App.css';
import React, { Component } from 'react';
import Routes from './routes';

class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Routes />;
  }
}

export default App;
