import React, { Component } from "react";
import "./Chat.css";
import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      etat: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
  }
  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() {}

  hide = () => {
    this.setState({
      etat: false,
    });
  };

  show = () => {
    this.setState({
      etat: true,
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    if (this.state.value !== "") {
      let uid = firebase
        .database()
        .ref()
        .child("questionsNovartisDIC2022")
        .push().key;
      let uid2 = firebase
        .database()
        .ref()
        .child("questionsNovartisDIC2022")
        .push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        question: this.state.value,
        date: n,
      };

      let updates = {};
      let updates2 = {};

      updates["/questionsNovartisDIC2022/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            etat: false,
            value: "",
          })
        );

      updates2["/questionsNovartisDIC2022Save/" + uid] = data;
      firebase.database().ref().update(updates2);

      event.preventDefault();
    }
  }

  render() {
    if (this.state.etat === true) {
      var templateChate = (
        <div className="containerChat">
          <div className="tiret" onClick={this.hide}>
            <a>-</a>
          </div>
          <div className="disclemer">
            Merci de ne pas utiliser ce Chat pour des discussions mais
            uniquement pour adresser vos questions au modérateur. Il y aura un
            temps dédié pour y répondre
          </div>
          <div className="TitleQuestion">Posez vos questions ici</div>
          <form onSubmit={this.handleSubmit}>
            <label>
              <textarea
                value={this.state.value}
                onChange={this.handleChange}
                placeholder=""
              />
            </label>
            <br></br>
            <input type="submit" value="Envoyer" className="btnValider" />
          </form>
        </div>
      );
    }

    return (
      <span>
        {templateChate}
        <div className="vignetteQO" onClick={this.show}>
          <img
            className="vignetteQOimg pointer"
            src="https://quiz.forumhepatogilead.com/IMG/Bouton-QAO-Campus-FH2024.png"
            alt="vignette questions aux orateurs"
          ></img>
        </div>
      </span>
    );
  }
}

export default Chat;
