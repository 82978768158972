import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="stfooter">
          <img
            //src="https://echowebline.com/live/IMG/Footer_1280px-03.png"
            src="https://alexion-nmosd.com/IMG/FOOTER_ALEXION.png"
            width="100%"
          />
        </div>
      </div>
    );
  }
}

export default Footer;
