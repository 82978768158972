import React, { Component } from "react";
import "./Admin.css";
import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etat1: 0,
      etat2: 0,
      etat3: 0,
      etat4: 0,
      etat5: 0,
      etat6: 0,
      etat7: 0,
      etat8: 0,
      etat_satisfaction: 0,
      playerMBS: 0,
      player2: 0,
      player: 0,
      etat_satisfaction3: 0,
      etat_satisfaction4: 0,
      next: 0,
    };
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
  }
  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() {}

  AfficherQuizz3 = () => {
    if (this.state.etat3 !== 1) {
      firebase
        .database()
        .ref("iframesatisfactionCPTS")
        .set(1)
        .then(this.setState({ etat3: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz3 = () => {
    if (this.state.etat3 !== 0) {
      firebase
        .database()
        .ref("iframesatisfactionCPTS")
        .set(0)
        .then(this.setState({ etat3: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  /*

   AfficherQuizz = () => {
    if (this.state.etat1 !== 1) {
      firebase
        .database()
        .ref("SondageCPTS")
        .set(1)
        .then(this.setState({ etat1: 1 }));
      console.log("Afficher Quizz 1");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz = () => {
    if (this.state.etat1 !== 0) {
      firebase
        .database()
        .ref("SondageCPTS")
        .set(0)
        .then(this.setState({ etat1: 0 }));
      console.log("Cacher Quzz 1");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz2 = () => {
    if (this.state.etat2 !== 1) {
      firebase
        .database()
        .ref("NextCPTS")
        .set(1)
        .then(this.setState({ etat2: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz2 = () => {
    if (this.state.etat2 !== 0) {
      firebase
        .database()
        .ref("NextCPTS")
        .set(0)
        .then(this.setState({ etat2: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

   AfficherQuizz4 = () => {
    if (this.state.etat4 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz4Depistage")
        .set(1)
        .then(this.setState({ etat4: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz4 = () => {
    if (this.state.etat4 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz4Depistage")
        .set(0)
        .then(this.setState({ etat4: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };
  AfficherQuizz5 = () => {
    if (this.state.etat5 !== 1) {
      firebase
        .database()
        .ref('iframeQuizz5BMS')
        .set(1)
        .then(this.setState({ etat5: 1 }));
      console.log('Afficher Quiz');
    } else alert('le Quizz est déjà affiché');
  };

  CacherQuizz5 = () => {
    if (this.state.etat5 !== 0) {
      firebase
        .database()
        .ref('iframeQuizz5BMS')
        .set(0)
        .then(this.setState({ etat5: 0 }));
      console.log('Quiz Cacher');
    } else alert('le Quizz est déjà caché');
  };

  AfficherQuizz6 = () => {
    if (this.state.etat6 !== 1) {
      firebase
        .database()
        .ref('iframeQuizz6BMS')
        .set(1)
        .then(this.setState({ etat6: 1 }));
      console.log('Afficher Quiz');
    } else alert('le Quizz est déjà affiché');
  };

  CacherQuizz6 = () => {
    if (this.state.etat6 !== 0) {
      firebase
        .database()
        .ref('iframeQuizz6BMS')
        .set(0)
        .then(this.setState({ etat6: 0 }));
      console.log('Quiz Cacher');
    } else alert('le Quizz est déjà caché');
  };

  AfficherQuizz7 = () => {
    if (this.state.etat7 !== 1) {
      firebase
        .database()
        .ref('iframeQuizz7BMS')
        .set(1)
        .then(this.setState({ etat7: 1 }));
      console.log('Afficher Quiz');
    } else alert('le Quizz est déjà affiché');
  };

  CacherQuizz7 = () => {
    if (this.state.etat7 !== 0) {
      firebase
        .database()
        .ref('iframeQuizz7BMS')
        .set(0)
        .then(this.setState({ etat7: 0 }));
      console.log('Quiz Cacher');
    } else alert('le Quizz est déjà caché');
  };

  AfficherQuizz8 = () => {
    if (this.state.etat8 !== 1) {
      firebase
        .database()
        .ref('iframeQuizz8BMS')
        .set(1)
        .then(this.setState({ etat8: 1 }));
      console.log('Afficher Quiz');
    } else alert('le Quizz est déjà affiché');
  };

  CacherQuizz8 = () => {
    if (this.state.etat8 !== 0) {
      firebase
        .database()
        .ref('iframeQuizz8BMS')
        .set(0)
        .then(this.setState({ etat8: 0 }));
      console.log('Quiz Cacher');
    } else alert('le Quizz est déjà caché');
  };


  AfficherSatisfaction3 = () => {
    if (this.state.etat_satisfaction3 !== 1) {
      firebase
        .database()
        .ref('satisfactionPlayermedic')
        .set(1)
        .then(this.setState({ etat_satisfaction3: 1 }));
      console.log('Afficher Medic');
    } else alert('le Medic est déjà affiché');
  };

  CacherSatisfaction3 = () => {
    if (this.state.etat_satisfaction3 !== 0) {
      firebase
        .database()
        .ref('satisfactionPlayermedic')
        .set(0)
        .then(this.setState({ etat_satisfaction3: 0 }));
      console.log('Medic Cacher');
    } else alert('le Medic est déjà caché');
  };

  AfficherSatisfaction4 = () => {
    if (this.state.etat_satisfaction4 !== 1) {
      firebase
        .database()
        .ref('satisfactionPlayermarket')
        .set(1)
        .then(this.setState({ etat_satisfaction4: 1 }));
      console.log('Afficher Market');
    } else alert('Market est déjà affiché');
  };

  CacherSatisfaction4 = () => {
    if (this.state.etat_satisfaction4 !== 0) {
      firebase
        .database()
        .ref('satisfactionPlayermarket')
        .set(0)
        .then(this.setState({ etat_satisfaction4: 0 }));
      console.log('Market Cacher');
    } else alert('le Medic est déjà caché');
  };

   AfficherPlayer = () => {
    if (this.state.playerMBS !== 1) {
      firebase
        .database()
        .ref("PlayerNovartisSANDOZV3")
        .set(1)
        .then(this.setState({ playerMBS: 1 }));
      console.log("Afficher Le player");
    } else alert("le player est déjà affiché");
  };

  CacherPlayer = () => {
    if (this.state.playerMBS !== 0) {
      firebase
        .database()
        .ref("PlayerNovartisSANDOZV3")
        .set(0)
        .then(this.setState({ playerMBS: 0 }));
      console.log("Satisfaction Cacher");
    } else alert("le player est déjà caché");
  };
*/

  AfficherPlayer2 = () => {
    if (this.state.player2 !== 1) {
      firebase
        .database()
        .ref("QuizPHARO_P")
        .set(1)
        .then(this.setState({ player2: 1 }));
      console.log("Afficher Le player");
    } else alert("le player est déjà affiché");
  };

  CacherPlayer2 = () => {
    if (this.state.player2 !== 0) {
      firebase
        .database()
        .ref("QuizPHARO_P")
        .set(0)
        .then(this.setState({ player2: 0 }));
      console.log("Satisfaction Cacher");
    } else alert("le player est déjà caché");
  };

  AfficherPlayer = () => {
    if (this.state.player !== 2) {
      firebase
        .database()
        .ref("PlayerNovartisATSP")
        .set(2)
        .then(this.setState({ player: 2 }));
      console.log("Afficher Le player");
    } else alert("le player est déjà affiché");
  };

  CacherPlayer = () => {
    if (this.state.player !== 0) {
      firebase
        .database()
        .ref("PlayerNovartisATSP")
        .set(0)
        .then(this.setState({ player: 0 }));
      console.log("Satisfaction Cacher");
    } else alert("le player est déjà caché");
  };

  AfficherNext = () => {
    if (this.state.next !== 1) {
      firebase
        .database()
        .ref("NextPHARO")
        .set(1)
        .then(this.setState({ next: 1 }));
      console.log("Afficher Le NextPHARO");
    } else alert("le player est déjà affiché");
  };

  CacherNext = () => {
    if (this.state.next !== 0) {
      firebase
        .database()
        .ref("NextPHARO")
        .set(0)
        .then(this.setState({ next: 0 }));
      console.log("NextPHARO Cacher");
    } else alert("le player est déjà caché");
  };

  render() {
    var template = "";
    //console.log(this.props.match.params.token);

    if (this.props.match.params.token === "34EKerh7k6rVKgc552KPk4hkpQMznZ8b") {
      template = (
        <div className="container">
          <h1> Panel d'administration</h1>

          {/*

             <div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Satisfaction</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz3}>
              Afficher Satisfaction
            </button>
            <button className="btn2" onClick={this.CacherQuizz3}>
              Cacher Satisfaction
            </button>
          </div>

<div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Sondage </span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz}>
              Afficher Sondage
            </button>
            <button className="btn2" onClick={this.CacherQuizz}>
              Cacher Sondage
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Inscription</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz2}>
              Afficher Inscription
            </button>
            <button className="btn2" onClick={this.CacherQuizz2}>
              Cacher Inscription
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 4</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz4}>
              Afficher Quizz 4
            </button>
            <button className="btn2" onClick={this.CacherQuizz4}>
              Cacher Quizz 4
            </button>
          </div>

       
           <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{' '}
              <span> Quizz 5</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz5}>
              Afficher Quizz 5
            </button>
            <button className="btn2" onClick={this.CacherQuizz5}>
              Cacher Quizz 5
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{' '}
              <span> Quizz 6</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz6}>
              Afficher Quizz 6
            </button>
            <button className="btn2" onClick={this.CacherQuizz6}>
              Cacher Quizz 6
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{' '}
              <span> Quizz 7</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz7}>
              Afficher Quizz 7
            </button>
            <button className="btn2" onClick={this.CacherQuizz7}>
              Cacher Quizz 7
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{' '}
              <span> Quizz 8</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz8}>
              Afficher Quizz 8
            </button>
            <button className="btn2" onClick={this.CacherQuizz8}>
              Cacher Quizz 8
            </button>
          </div>
          */}

          <h3>Mes players</h3>
          <hr></hr>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe{" "}
              <span> Quiz </span>
            </p>
            <button className="btn1" onClick={this.AfficherPlayer2}>
              Afficher Quiz
            </button>
            <button className="btn2" onClick={this.CacherPlayer2}>
              Cacher Quiz
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe{" "}
              <span> de la satisfaction</span>
            </p>
            <button className="btn1" onClick={this.AfficherNext}>
              Afficher Satisfaction
            </button>
            <button className="btn2" onClick={this.CacherNext}>
              Cacher Satisfaction
            </button>
          </div>
        </div>
      );
    }
    return <div>{template}</div>;
  }
}

export default Admin;
