import React, { Component } from "react";
import screenfull from "screenfull";
import "./Player.css";
import Header from "./Header";
import Footer from "./Footer";
import Chat from "./Chat";

import axios from "axios";
import ReactLoading from "react-loading";

import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";
import ReactPlayer from "react-player";
import { io } from "socket.io-client";

var socket = io("https://h8.events.novartis.fr", {
  transports: ["polling"],
  path: "/PHARO/api/socket.io",
});

class Player extends Component {
  constructor(props, context) {
    super(props, context);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
    this.state = {
      etat: 2,
      localstate: 2,
      url: null,
      pip: false,
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,

      iframeIVI: 0,
      iframeIVI2: 0,

      iframeIVI16: 0,
      iframeIVI17: 0,

      iframePlayer: 0,
      sessionid: "",
      urlYoutube: "",

      tmpToken: "",
      statusToken: "",
      idSession: "",

      bottomtmp: "42%",
      widthtmp: "200px",
      NextSandoz16: 0,

      socketClosePHARO: 2,
    };

    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleLocalState = this.handleLocalState.bind(this);
  }
  componentWillMount() {
    this.db = firebase.database();

    //Satisfaction
    /*
    this.db.ref('iframesatisfactionPHARO1').on('value', (snap) => {
      this.setState({ iframeIVI16: snap.val() });
    });
    
    
     this.db.ref("iframesatisfactionPHARO").on("value", (snap) => {
      this.setState({ iframeIVI17: snap.val() });
    });*/

    // Url youtube
    this.db.ref("YoutubelinkPHARO_P").on("value", (snap) => {
      this.setState({ urlYoutube: snap.val() });
    });

    // Url Next
    this.db.ref("NextPHARO").on("value", (snap) => {
      this.setState({ NextSandoz16: snap.val() });
    });
    this.db.ref("QuizPHARO_P").on("value", (snap) => {
      this.setState({ iframeIVI2: snap.val() });
    });

    this.db.ref("socketClosePHARO").on("value", (snap) => {
      this.setState({ socketClosePHARO: snap.val() });
    });
  }
  componentDidMount() {
    socket.emit(
      "identify",
      this.props.match.params.token,
      "pleniere",
      function (data) {
        console.log("************************************");
        console.log(data);
      }
    );

    axios
      .post(`https://h8.events.novartis.fr/PHARO/api/token/useToken`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ statusToken: res.status });
      })
      .catch((err) => this.setState({ statusToken: "err" }));

    axios
      .post(`https://h8.events.novartis.fr/PHARO/api/session/start`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ idSession: res.data._id });
      });
    window.removeEventListener("beforeunload", this.callEvent);
  }

  componentDidUpdate() {
    if (this.state.socketClosePHARO === 1) {
      socket.emit("play", this.props.match.params.token, function (data) {});
      console.log("play");
    } else if (this.state.socketClosePHARO === 0) {
      socket.close();
      console.log("close");
    }
  }

  componentWillUnmount() {}

  callEvent = (e) => {
    const _props = this.props;
    e.preventDefault();

    axios
      .post(`https://h8.events.novartis.fr/PHARO/api/session/end`, {
        session: this.state.idSession,
      })
      .then((res) => {
        console.log("end session" + res);
      });
  };

  componentWillUnmount() {
    axios
      .post(`https://h8.events.novartis.fr/PHARO/api/session/end`, {
        session: this.state.idSession,
      })
      .then((res) => {
        console.log("end session" + res);
      });
  }

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      localstate: 1,
      pip: false,
    });

    setTimeout(function () {
      screenfull.request();
    }, 200);
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handlePlay = () => {
    // console.log('onPlay');
    this.setState({ playing: true });
  };
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  handleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    } else {
    }
  }

  play() {
    this.player.play();
  }
  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  renderLoadButton = (url, label) => {
    return (
      <button className="play-btn" onClick={() => this.load(url)}>
        {label}
      </button>
    );
  };

  ref = (player) => {
    this.player = player;
  };

  handleLocalState() {
    this.setState({ localstate: 1 });
    // console.log('yesy');
  }

  render() {
    var urlNext =
      "https://h8.events.novartis.fr/SYNAPSES/be12595a395da05a3cfd81079d1d9ed5730bde1678284541907/satisfaction";

    var urlQuiz = "https://synapsesquiz.com/quiz/#/pleniere";

    if (this.state.NextSandoz16 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlNext}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI2 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuiz}
          ></iframe>
        </div>
      );
    }

    var templateHide = <div className="ContainerHide"></div>;
    const { url, playing } = this.state;

    var player = "";
    if (this.state.iframePlayer === 2) {
      player = (
        <div>
          <ReactPlayer
            ref={this.ref}
            width="100%"
            height="100%"
            url={url}
            onPlay={this.handlePlay}
            playing={playing}
          />
          {this.renderLoadButton("", "")}
        </div>
      );
    } else {
      player = (
        <div>
          <ReactPlayer
            ref={this.ref}
            width="100%"
            height="100%"
            url={url}
            onPlay={this.handlePlay}
            playing={playing}
          />
          {this.renderLoadButton("", "")}
        </div>
      );
    }

    const SEPARATOR = " · ";

    if (this.state.statusToken === 200) {
      var template1 = (
        <div className="PlayerFullScreen">
          {/*   <iframe
             src="//iframe.dacast.com/b/50026/c/468891"
            width="100%"
            height="100%"
            scrolling="no"
            allow="autoplay"
            title="fullPlayer"
          ></iframe>
          
          
          */}

          <iframe
            width="100%"
            height="100%"
            src={this.state.urlYoutube}
            //src="//iframe.dacast.com/b/50026/c/468891" MSD
            //src="//iframe.dacast.com/b/50026/c/428043"
            //src="//iframe.dacast.com/live/d6abfb2d213d0c9a2d231908d9336438/81992fdc1ab6df9294823e15138db1cc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
            //
            // onClick={() => this.load(url)}
          ></iframe>

          <Chat />
          {template2}
          {templateHide}

          <div
            onClick={this.handleFullscreen}
            className="vide"
            // style={{ bottom: this.state.bottomtmp, width: this.state.widthtmp }}
          ></div>
        </div>
      );
    }

    if (this.state.statusToken === "err") {
      var template1 = (
        <div className="PlayerFullScreen">
          {/*   <iframe
             src="//iframe.dacast.com/b/50026/c/468891"
            width="100%"
            height="100%"
            scrolling="no"
            allow="autoplay"
            title="fullPlayer"
          ></iframe>
          
          
          */}

          <iframe
            width="100%"
            height="100%"
            src={this.state.urlYoutube}
            //src="//iframe.dacast.com/b/50026/c/468891" MSD
            //src="//iframe.dacast.com/b/50026/c/428043"
            //src="//iframe.dacast.com/live/d6abfb2d213d0c9a2d231908d9336438/81992fdc1ab6df9294823e15138db1cc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
            //
            // onClick={() => this.load(url)}
          ></iframe>

          <Chat />
          {template2}
          {templateHide}

          <div
            onClick={this.handleFullscreen}
            className="vide"
            // style={{ bottom: this.state.bottomtmp, width: this.state.widthtmp }}
          ></div>
        </div>
      );
    }

    {
      /**
       *  template1 = (
        <div className="containerIncorrect">
          <div className="AccesRefuse">Accès refusé</div>
          <hr className="hr"></hr>
          <div className="textIncorrect">
            <div className="first">
              Le lien sur lequel vous avez cliqué semble invalide. Veuillez
              réessayer.
            </div>
            Si vous êtes le médecin inscrit destinataire de ce lien alors vous
            pouvez appeler notre hotline au
            <span style={{ color: "#0000FF" }}> 06 66 45 26 53</span>
          </div>
        </div>
      );
       */
    }

    return <div>{template1}</div>;
  }
}

export default Player;
