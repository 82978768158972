import React, { Component } from 'react';
import './Dashboard.css';
import Chat from './Chat';
import Moderation from './Moderation';
import { Firebase_Config } from '../Config/config';
import * as firebase from 'firebase/app';
import 'firebase/database';

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.state = {
      urlIternetClicker: '',
      urlVmixcallUser: '',
    };
  }
  componentWillMount() {
    this.db = firebase.database();

    //get url vMixCall
    this.db.ref('urlVmixcallUserCelia').on('value', (snap) => {
      this.setState({ urlVmixcallUser: snap.val() });
    });

    //get url internet cliker
    this.db.ref('urlIternetClickerR1').on('value', (snap) => {
      this.setState({ urlIternetClicker: snap.val() });
    });
  }
  componentDidMount() {}

  render() {
    return (
      <div className="conatiner">
        <div className="container1">
          <div className="contenuVmix">
            <iframe
              src={this.state.urlVmixcallUser}
              frameBorder="0"
              allow="camera;microphone"
              allowFullScreen=""
            ></iframe>
          </div>
          <div className="contenuChat">
            <Moderation />
          </div>
        </div>
        <div className="container2">
          <div className="contenuClic">
            <iframe
              src={this.state.urlIternetClicker}
              frameBorder="0"
              allow="camera;microphone"
              allowFullScreen=""
            ></iframe>
          </div>
          <div className="contenuChatsend"> {/**<Chat /> */}</div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
