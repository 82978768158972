import React, { Component } from 'react';
import './Header.css';

class Header extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="col-sm-3 col-md-12  stheader">
          <img
            //src="https://echowebline.com/live/IMG/Header_1280px-02.png"
            src="https://alexion-nmosd.com/IMG/HEADER_ALEXION.png"
            width="100%"
          />
        </div>
      </div>
    );
  }
}

export default Header;
